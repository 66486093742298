import LStake_BNB_Abi from '../contract/ABI/Local/stake.json'
import PStake_BNB_Abi from '../contract/ABI/Production/stake.json'


let key = {};
let Back_Url = "";
let image_Url = ""
let StakeAPI = "",UserAPI=""
var EnvName = "demo";

if (EnvName === "local") {
    Back_Url = "http://localhost:2024/api";
    image_Url = "http://localhost:2024/images/";
    StakeAPI = "http://localhost:2024/stake"
    UserAPI = "http://localhost:2024/user_v1"
}

if (EnvName === "production") {
    Back_Url = "https://backend.rorowallet.com/api";
    image_Url = "https://backend.rorowallet.com/api/images/"
    StakeAPI = "http://localhost:2021/stake"
    UserAPI = "http://localhost:2021/user_v1"
}

if (EnvName === "demo") {
    Back_Url = "https://rorowalletdemoapi.maticz.in/api";
    image_Url = "https://rorowalletdemoapi.maticz.in/api/images/"
    StakeAPI = "https://rorowalletdemoapi.maticz.in/stake"
    UserAPI = "https://rorowalletdemoapi.maticz.in/user_v1"
}
key = {
    AdminAPI: `${Back_Url}`,
    image_Url: image_Url,
    StakeAPI,
    UserAPI,
    Stake_BNB : EnvName === "production" ? "0x799C99D79aebf9AD33BBCf9665cA6c992aD27dEE":"0x799C99D79aebf9AD33BBCf9665cA6c992aD27dEE",
    Stake_BNB_Abi : EnvName === "production" ? LStake_BNB_Abi : PStake_BNB_Abi,
    CHAINID : EnvName === "production" ? 97 : 56,
    RPC_URL: EnvName === "production" ?  "https://bsc-dataseed1.binance.org" : "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

export default key